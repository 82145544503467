import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaLocationArrow, FaTimes } from 'react-icons/fa';

import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  Marker,
  TrafficLayer,
  useJsApiLoader
} from '@react-google-maps/api';
import { useRef, useState } from 'react';

const center = { lat: -33.438230, lng: -70.647479 };

function App() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  })

  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef()

  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' })
  const inputWidth = useBreakpointValue({ base: '100%', md: '100%' })

  if (!isLoaded) {
    return <SkeletonText />
  }

  async function calculateRoute() {
    try {
      let fecha = new Date();
      if (originRef.current.value === '' || destinationRef.current.value === '') {
        return;
      }
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: originRef.current.value,
        destination: destinationRef.current.value,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(fecha.setMinutes(fecha.getMinutes() + 5)), // Utiliza la hora actual para obtener estimaciones en tiempo real
          trafficModel: 'pessimistic', // Puedes cambiar esto según tus necesidades (bestguess, pessimistic, optimistic)
        },
      });
    
      setDirectionsResponse(results);
      setDistance(results.routes[0].legs[0].distance.text);
      setDuration(results.routes[0].legs[0].duration_in_traffic.text);
    
      // Enviar los valores de distancia y tiempo al componente padre
      const dataToSend = {
        distance: results.routes[0].legs[0].distance.text,
        duration: results.routes[0].legs[0].duration_in_traffic.text,
        origin: originRef.current.value, 
        destination: destinationRef.current.value
      };

      // Convertir el objeto a JSON
      const dataMap = JSON.stringify(dataToSend);

    // Guardar en el local storage bajo la clave 'usuario'
      localStorage.setItem('dataMap', dataMap);
    
      window.parent.postMessage(dataToSend, 'https://www.otigo.cl'); 
      window.parent.postMessage(dataToSend, 'http://www.otigo.cl');
      window.parent.postMessage(dataToSend, 'https://otigo.cl');
      window.parent.postMessage(dataToSend, 'http://otigo.cl');
      window.parent.postMessage(dataToSend, 'http://localhost:3001'); 
      window.parent.postMessage(dataToSend, 'http://localhost:3000'); 
    } catch (error) {
      console.error('Error en el cálculo de la ruta:', error);
      // Aquí puedes manejar el error como consideres adecuado, como mostrar un mensaje al usuario o registrar el error.
    }
    
  }

  function clearRoute() {
    setDirectionsResponse(null)
    setDistance('')
    setDuration('')
    originRef.current.value = ''
    destinationRef.current.value = ''
  }

  function onLoadOrigin(autocomplete) {
    autocomplete.setComponentRestrictions({ country: 'cl' }); // Limita a Chile
  }
  
  function onLoadDestination(autocomplete) {
    autocomplete.setComponentRestrictions({ country: 'cl' }); // Limita a Chile
  }
  
  function onPlaceChangedOrigin() {
    const place = originRef.current.getPlace();
    console.log(place);
  }
  
  function onPlaceChangedDestination() {
    const place = destinationRef.current.getPlace();
    console.log(place);
  }

  return (
    <Flex
      position='relative'
      flexDirection='column'
      alignItems='center'
      h='100vh'
      w='100vw'
    >
      <Box position='absolute' left={0} top={0} h='100%' w='100%'>
        {/* Google Map Box */}
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
          }}
          onLoad={map => setMap(map)}
        >
          <TrafficLayer autoUpdate />
          <Marker position={center} />
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </Box>
      <Box
        p={4}
        borderRadius='lg'
        m={4}
        bgColor='white'
        shadow='base'
        w='90%'
        maxW='container.md'
        zIndex='1'
      >
        <VStack spacing={4} align='stretch'>
          <Stack direction={flexDirection} spacing={4} w='100%'>
            <Box flexGrow={1} width={inputWidth}>
              <Autocomplete onLoad={onLoadOrigin} onPlaceChanged={onPlaceChangedOrigin}>
                <Input type='text' placeholder='Desde' ref={originRef} />
              </Autocomplete>
            </Box>
            <Box flexGrow={1} width={inputWidth}>
              <Autocomplete  onLoad={onLoadDestination} onPlaceChanged={onPlaceChangedDestination}>
                <Input
                  type='text'
                  placeholder='Hasta'
                  ref={destinationRef}
                />
              </Autocomplete>
            </Box>
            <Box display="flex" justifyContent="center" w="100%">
              <ButtonGroup>
                <Button colorScheme='blue' type='submit' onClick={calculateRoute}>
                  Calcular Ruta
                </Button>
                <IconButton
                  aria-label='center back'
                  icon={<FaTimes />}
                  onClick={clearRoute}
                />
              </ButtonGroup>
            </Box>
          </Stack>
          <HStack spacing={4} justifyContent='space-between' w='100%'>
            <Text>Distance: {distance} </Text>
            <Text>Duration: {duration} </Text>
            <IconButton
              aria-label='center back'
              icon={<FaLocationArrow />}
              isRound
              onClick={() => {
                map.panTo(center)
                map.setZoom(15)
              }}
            />
          </HStack>
        </VStack>
      </Box>
    </Flex>
  )
}

export default App;
